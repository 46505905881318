window.__tnt || (window.__tnt = {});
__tnt.video || (__tnt.video = {});
__tnt.video.plugins || (__tnt.video.plugins = {});

__tnt.video.vast = {
    url:function(url,keywords){
        var date = new Date(),
            canonicalUrl = '',
            fullUrl = encodeURIComponent(document.location.href),
            vidHeight = 480,
            vidWidth = 640,
            firstVideo = document.querySelector('video.tnt-video'),
            keywordsMeta = document.head.querySelector('meta[name=keywords]'),
            keywordsString = "",
            pathname = document.body.getAttribute('data-path');

            if(document.head.querySelector('link[rel=canonical]')){
                canonicalUrl = encodeURIComponent(document.head.querySelector('link[rel=canonical]').href);
            }

            if(keywordsString == "" && keywordsMeta){
                keywordsString = keywordsMeta.content.trim();
                if(keywordsString.endsWith(',')){
                    keywordsString = keywordsString.slice(0,-1);
                }
            }

            if(firstVideo){
                vidHeight = firstVideo.height;
                vidWidth = firstVideo.width;
            }
            
        if(typeof(keywords)=="undefined"){
            keywords = [
                {'key':/%%canonical_url%%/g, 'value': canonicalUrl},
                {'key':/%%full_url%%/g, 'value': fullUrl },
                {'key':/%%timestamp%%/g, 'value': date.getTime()},
                {'key':/%%width%%/g, 'value': vidWidth },
                {'key':/%%height%%/g, 'value': vidHeight },
                {'key':/%%random%%/g, 'value': Math.trunc(Math.random()*Math.pow(10,6))},
                {'key':/%%keywords%%/g, 'value': keywordsString },
                {'key':/%%path%%/g, 'value': pathname }
            ];
        }

        if(typeof(url)!='undefined'){
            if(keywords.length){ for(var i=0; i < keywords.length;i++){
                try{
                    url = url.replace(keywords[i].key, keywords[i].value);
                }catch(error){ }
            }};

            return url;
        }

        return false;
    },
    urlCheck:function(s){ 
        try {
            var url = new URL(s);
            
            return true;
        } catch (_) {
            return false;
        }

        return true;
    },
    feed:"",
    feedRetrieved:false
};

if(typeof(window.CustomEvent)==='function'){
    window.videoReady = new CustomEvent('tntVideoReady');
    window.videoEnded = new CustomEvent('tntVideoEnded');
}else{
    var videoReady = document.createEvent('Event');
    var videoEnded = document.createEvent('Event');
    videoReady.initEvent('tntVideoReady',true,true,{});
    videoEnded.initEvent('tntVideoEnded',true,true,{});
}

__tnt.video.ready = function() {
    if(__tnt.video.vast.urlCheck(__tnt.video.vastUrl) && !__tnt.video.vastUrl.match("pubads.g.doubleclick.net") && typeof(window.fetch)==='function'){
         fetch(__tnt.video.vast.url(__tnt.video.vastUrl))
            .then(function(response){
                return response.text().then(function(text){
                    __tnt.video.vast.feed = text;
                    __tnt.video.vast.feedRetrieved = true;

                    document.dispatchEvent(videoReady);
                });
            })
            .catch(function(error){
                document.dispatchEvent(videoReady);
            });
    }else{
        document.dispatchEvent(videoReady);
    }
};

if (document.readyState === "complete") {
    __tnt.video.ready();
} else {
    document.addEventListener("DOMContentLoaded", __tnt.video.ready);
}

__tnt.video.advertising = function(player){
    var playerContainer = player.el().closest('.tnt-video-container'),
        playerVastUrl = player.el().getAttribute('data-vast-url');
        
    if(playerVastUrl == null){ playerVastUrl = __tnt.video.vastUrl; }

    if(playerContainer && __tnt.video.vast.urlCheck(playerVastUrl)){
        if(playerContainer.getAttribute('data-live')!='true'){
            __tnt.video.register(player);

            var options = {
                id: player.id()
            };
            if(__tnt.video.vastUrl.match("pubads.g.doubleclick.net") || (playerVastUrl != null && playerVastUrl != __tnt.video.vastUrl)){
                options.adTagUrl = __tnt.video.vast.url(playerVastUrl);
                options.prerollTimeout = 5000;
            }else{
                options.adsResponse = __tnt.video.vast.feed;
                options.prerollTimeout = 5000;
            }

            player.ima(options);

            player.one('play', function(){
                player.ima.initializeAdDisplayContainer();
            });

            player.one('adstart',function(){
                __tnt.video.blox.players[player.id()].adPlaying = true;
            });

            player.on('ads-manager',function(ev){
                var adsManager = ev.adsManager;

                adsManager.addEventListener(google.ima.AdEvent.Type.STARTED,function(adEvent){
                    switch (adEvent.type) {
                       case google.ima.AdEvent.Type.STARTED:
                       // Get the ad from the event.
                       var ad = adEvent.getAd(),
                           selectionCriteria = new google.ima.CompanionAdSelectionSettings();
                           selectionCriteria.resourceType = google.ima.CompanionAdSelectionSettings.ResourceType.STATIC;
                           selectionCriteria.creativeType = google.ima.CompanionAdSelectionSettings.CreativeType.IMAGE;
                           selectionCriteria.sizeCriteria = google.ima.CompanionAdSelectionSettings.SizeCriteria.IGNORE;

                       // Get a list of companion ads for an ad slot size and CompanionAdSelectionSettings
                       if(__tnt.video.companion){
                           var companionAds = ad.getCompanionAds(__tnt.video.companion.width, __tnt.video.companion.height, selectionCriteria),
                               companionAd = companionAds[0],
                               companionAdId = "";

                           if(__tnt.video.companion.id){ companionAdId = __tnt.video.companion.id; }

                           if(typeof(companionAd)!="undefined" && companionAdId != ""){
                               var content = companionAd.getContent(),
                                   div = document.getElementById(companionAdId);

                               if(content && div){ div.innerHTML = content; }
                           }
                       }

                       break;
                   }
               });
           });

            player.one('adend',function(){
                var media = player.el().querySelector('source').src,
                    mimetype = __tnt.video.mimetype(media),
                    seconds = __tnt.video.blox.players[player.id()].resumeAt;

                __tnt.video.blox.players[player.id()].adPlaying = false;
                
                // Resume video
                player.currentTime(seconds);
                setTimeout(function () {
                    player.play();
                }, 250);
            });
        }
    }
};
